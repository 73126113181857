<template>
  <div class="UserHome">
    <!-- <div class="wrapperTop">
      <div class="left">
    <div class="showData">
          <h3 class="index_title">统计分析</h3>   style="margin-top:0.2rem;height: 66vh;"-->
    <div class="bottomDiv1" style="height: auto">
      <div class="index_title" style="border-bottom: none">设备管理</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <!-- <el-form-item label="选择省份:">
            <el-select
              v-model="FormData.province"
              placeholder="请选择省"
              @change="handleProvinceChange"
              style="width: 100%"
            >
              <el-option
                v-for="provinces in provinceList"
                :key="provinces.value"
                :value="provinces.label"
                :label="provinces.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="选择市:">
            <el-select
              v-model="FormData.city"
              placeholder="请选择市"
              @change="handleCityChange"
              style="width: 100%"
            >
              <el-option
                v-for="citys in cityList[0]"
                :key="citys.value"
                :value="citys.label"
                >{{ citys.label }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="选择区/县:">
            <el-select
              v-model="FormData.county"
              placeholder="请选择区/县"
              style="width: 100%"
            >
              <el-option
                v-for="countys in countyList[0]"
                :key="countys.value"
                :value="countys.label"
                >{{ countys.label }}</el-option
              >
            </el-select>
          </el-form-item> -->
          <!-- </el-col>
            <el-col :span="7"> -->
          <el-form-item label="站点名称:">
            <el-input
              v-model="FormData.station_name"
              placeholder="请输入站点名称"
            ></el-input>
          </el-form-item>
          <!-- </el-col>
            <el-col :span="7"> -->
          <el-form-item label="站点id:">
            <el-input
              v-model="FormData.device_id"
              placeholder="请输入设备编号"
            ></el-input>
          </el-form-item>
          <!-- </el-col>
            <el-col :span="1"> -->
          <div class="BtnSearch" style="margin: 0 auto" @click="searchBtn()">查询</div>
          <!-- </el-col>
          </el-row> -->
        </el-form>
      </div>
    </div>
    <div class="bottomDiv1">
      <!-- <div class="index_title">历史订单</div> -->
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          style="width: 95%; margin: 0px auto; height: auto; overflow-y: auto"
        >
          <el-table-column label="站点id" prop="device_id" />
          <el-table-column label="站点名称" prop="station_name" width="150" />
          <el-table-column label="所属省" prop="province" width="100" />
          <el-table-column label="所属市" prop="city" width="100" />
          <el-table-column label="所属县区" prop="county" width="100" />
          <el-table-column label="详细地址" prop="community" width="150" />
          <el-table-column
            label="充电状态"
            prop="charging_station"
            width="80"
          />
          <el-table-column label="功率" prop="wx_id" />
          <el-table-column label="计费模板" prop="price_id" />
          <el-table-column label="操作" align="center" fixed="right" width="100">
            <template #default="scope">
              <div
                class="BtnSearch"
                @click="openCharg(scope.$index, scope.row)"
              >
                开启充电
              </div>
              <div class="BtnSearch" @click="offCharg(scope.$index, scope.row)">
                关闭充电
              </div>
              <!-- <div
                class="BtnSearch1 BtnSearch"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</div
              > -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
        <!-- 开启充电 -->
        <el-dialog
          v-model="Dialog1.editLevy"
          title="开启充电"
          width="9rem"
          :before-close="closeDialog1"
          class="dialog"
          style="height: auto"
        >
          <div class="formDiv">
            <el-form
              ref="FormRules"
              :rules="rules"
              :model="FormData"
              label-position="right"
              label-width="100px"
            >
                  <el-form-item label="设备编号:">
                    <el-input
                      v-model="Dialog1.DialogData.device_id"
                      placeholder="请输入站点名称"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="端口号:">
                    <el-select
                      v-model="Dialog1.DialogData.socket"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in charg1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="充电时长:">
                    <el-select
                      v-model="Dialog1.DialogData.charge_time"
                      placeholder="请选择"
                      width="100%"
                    >
                      <el-option
                        v-for="item in time1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.label"
                      />
                    </el-select>
                  </el-form-item>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog1">取消</el-button>
              <el-button class="Btn" @click="submitDialog1">开启充电</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* 标题文字样式 */
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
.UserHome .formDiv {
  width: 8rem;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 0.5rem;
}
.bottomDiv1 .BtnSearch{
    width: 2rem;
    margin: 0 0 0.1rem 0;
}
.statistics {
  display: flex;
  width: 100%;
  margin: 0.1rem auto;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
.statistics > div {
  margin: 0.05rem;
  width: 2.5rem;
  height: 2rem;
  background-size: 100% 100%;
  text-align: center;
}
.statistics > div > div {
  margin: 0.4rem 0;
  cursor: pointer;
}
.statistics > div .s_title {
  font-size: 0.1rem;
  cursor: pointer;
  line-height: 0.5rem;
}
.statistics > div .s_water {
  font-size: 0.4rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 0.5rem;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060;
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec;
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22;
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300;
}
</style>

<script>
// import { ref } from 'vue'
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  sel_device,
  upd_device,
  start_charge,
  stop_charge,
} from "@/request/api";
import { ElMessage } from "element-plus";
// import ProvinceCityCountry from '../../assets/json/address'
import { regionData } from "element-china-area-data";
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        page:1
      },
      tableData: [
        {
          serial_number: "YH001",
          user_name: "张三",
        },
      ],
      // provinceData: ProvinceCityCountry.address,
      options: regionData,
      selectedOptions: "",
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",

      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
      Dialog2: {
        editLevy: false,
        DialogData: {},
      },
      loading: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 10,
      checked: 0,
      small: true,

      charg1: [
        {
          value: "1",
          label: "00",
        },
        {
          value: "2",
          label: "01",
        },
        {
          value: "3",
          label: "02",
        },
      ],
      charg2: [
        {
          value: "1",
          label: "dk001",
        },
        {
          value: "2",
          label: "dk002",
        },
        {
          value: "3",
          label: "dk003",
        },
      ],
      time1: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
      ],
    });
    const searchBtn = () => {
      getList();
    };
    const openCharg = (index, row) => {
      data.Dialog1.editLevy = true;
      data.Dialog1.DialogData = row;
    };
    const offCharg = (index, row) => {
      const dataa = {
        device_id: row.device_id,
      };
      stop_charge(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("已关闭充电");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("关闭失败");
        }
      });
    };
    const handleEdit = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const closeDialog1 = () => {
      data.Dialog1.editLevy = false;
    };

    const submitDialog = () => {
      const dataa = {
        id: data.Dialog.DialogData.id,
        device_id: data.Dialog.DialogData.device_id,
        station_name: data.Dialog.DialogData.station_name,
        community: data.Dialog.DialogData.community,
        province: data.Dialog.DialogData.value[0],
        city: data.Dialog.DialogData.value[1],
        county: data.Dialog.DialogData.value[2], 
      };
      upd_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("编辑成功");
            closeDialog();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };
    const submitDialog1 = () => {
      const dataa = {
        device_id: data.Dialog1.DialogData.device_id,
        socket: data.Dialog1.DialogData.socket,
        charge_time: data.Dialog1.DialogData.charge_time,
      };
      start_charge(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("已开启充电");
            closeDialog1();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("开启失败");
        }
      });
    };
    const getList = () => {
      const dataa = data.FormData;
      data.loading = true;
      sel_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const handleChange = (value) => {
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList()
      //
    };
    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
    };
    const handleProvinceChange = (val) => {
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      data.FormData.city = "";
      data.FormData.county = "";
      initCityList();
    };

    const initCityList = () => {
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      data.FormData.county = "";
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
    };
    onMounted(() => {
      getList();
      initProvinceList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      openCharg,
      offCharg,
      handleEdit,
      closeDialog,
      closeDialog1,
      submitDialog,
      submitDialog1,
      getList,
      handleChange,
      handleSizeChange,
      handleCurrentChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
    };
  },
};
</script>
